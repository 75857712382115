import {
    Route,
    Redirect,
    Switch,
} from "react-router-dom";
import FirebaseLogin from "../../components/firebaseLogin/firebaseLogin";
import LoginSuccessPage from "../loginSuccessPage/loginSuccessPage";

const FirebaseLoginPage = ({firebase, url}) => {
    return (
        <div>
            <Switch>
                <Route exact path={`${url}/portal`}>
                    <FirebaseLogin firebase={firebase} url={url} goto="portal" />
                </Route>
                <Route exact path={`${url}/extension`}>
                    <FirebaseLogin firebase={firebase} url={url} goto="extension"  />
                </Route>
                <Route exact path={`${url}/success`}>
                    <LoginSuccessPage />
                </ Route>
                <Redirect from={`${url}*`} to={`${url}/extension`} />
            </Switch>
        </div>
    );
}
 
export default FirebaseLoginPage;