import './tutorialgif.css';

const TutorialGif = ({ gif, name, header, desc }) => {
    return (
        <div className="tutorialGif">
            <h3 className="tutheader">{header}</h3>
            <p className="tutsmalltext">{desc}</p>
            <img width="100%" src={gif} alt={name} />
        </div>
    )
}

export default TutorialGif