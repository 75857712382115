import './header.css'
import chromeIcon from '../../assets/images/chromeIcon.png';
import firefoxIcon from '../../assets/images/firefoxIcon.png';
import { Button } from '@material-ui/core';
import config from '../../config';

const Header = () => {
    return (
        <div className={"headerWrapper"}>
            {/* <img className={"logoHeader"} alt={"Selectext logo"} src={logo} /> */}
            <h1 className="selectextLogoHeader">selec<span className="outlines">text</span></h1>
            <h2 className="descHeader">Copy text directly from videos with the Selectext browser extension</h2>
            <div className={"getItWrapper"} style={{flexWrap: 'wrap'}}>
                <a href={config.webStoreUrls.chrome} style={{all: 'unset'}} target="_blank" rel="noreferrer"><Button className="donateButton getItButton" style={{margin: 10}}>Get it on Chrome <img alt="" className={"smallChromeIcon"} src={chromeIcon}/></Button></a>
                <a href={config.webStoreUrls.firefox} style={{all: 'unset'}} target="_blank" rel="noreferrer"><Button className="donateButton getItButton" style={{margin: 10}}>Get it on Firefox <img alt="" className={"smallChromeIcon"} src={firefoxIcon}/></Button></a>
            </div>
        </div>
    )
}

export default Header