import firstSlide from "../../assets/images/videoScreenshotDemo/1.png";
import secondSlide from "../../assets/images/videoScreenshotDemo/2.png";
import thirdSlide from "../../assets/images/videoScreenshotDemo/3.png";

import Carousel from 'react-material-ui-carousel'

import "./videoScreenshotDemoStepper.css";

import VideoScreenshotDemoSlide from "../videoScreenshotDemoSlide/videoScreenshotDemoSlide";

let slides = [
  {
      src: firstSlide,
      name: "Copy the perfect screenshot"
  },
  {
      src: secondSlide,
      name: "Paste anywhere",
  },
  {
      src: thirdSlide,
      name: "Turn off",
  }
]


const VideoScreenshotDemoStepper = () => {

  return (

    <Carousel autoPlay={false} animation="slide" swipe={true} navButtonsAlwaysVisible={true}>
      {slides.map((slide) => <VideoScreenshotDemoSlide slide={slide}/>)}
    </Carousel>
  )
}

export default VideoScreenshotDemoStepper;