import { Button } from '@material-ui/core';
import chromeIcon from '../../assets/images/chromeIcon.png';
import firefoxIcon from '../../assets/images/firefoxIcon.png';
import config from '../../config';
import './topBanner.css';

const TopBanner = () => {
    return (<div className='topBannerWrapper'>
        <div className={"getItWrapper"} style={{ marginTop: 0, justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
            <h3 className='makesureheader' style={{ marginRight: '20px', marginLeft: '10px'}}>Make sure you have the extension installed before following the tutorial</h3>

            <a href={config.webStoreUrls.chrome} style={{all: 'unset'}} target="_blank" rel="noreferrer"><Button className="donateButton getItButton" style={{ marginRight: 20}}>Get it on Chrome <img alt="" className={"smallChromeIcon"} src={chromeIcon} /></Button></a>
            <a href={config.webStoreUrls.firefox} style={{all: 'unset'}} target="_blank" rel="noreferrer"><Button className="donateButton getItButton" style={{ fontSize: '20px', maxWidth: '100px', marginRight: '10px' }}>Get it on Firefox <img alt="" className={"smallChromeIcon"} src={firefoxIcon} /></Button></a>

        </div>

    </div>);
}

export default TopBanner;