import React from 'react';

import './App.css';
import HomePage
    from "./pages/homePage/homePage";
import FirebaseLogout from './components/firebaseLogout/firebaseLogout';
import firebase from 'firebase/app'
import "firebase/auth";
import {
    FirebaseAuthProvider
} from "@react-firebase/auth";


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import config from "./config"
import TutorialPage from './pages/tutorialPage/tutorialPage';
import Portal from './pages/portal/portal';
import FirebaseLoginPage from './pages/firebaseLoginPage/firebaseLoginPage';
import UpgradeRequestPage from './pages/upgradeRequestPage/upgradeRequestPage';
import SubscriptionRouter from './routers/subscriptionRouter/subscriptionRouter';
import PrivacyPolicyPage from './pages/privacyPolicyPage/privacyPolicyPage';


const stripePromise = loadStripe(config.stripeKey);

function App() {
    return (
        <div className="app">
            <Elements stripe={stripePromise}>
                <FirebaseAuthProvider {...config.firebase} firebase={firebase}>
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <HomePage stripePromise={stripePromise} firebase={firebase} />
                            </Route>
                            <Route exact path="/logout">
                                <FirebaseLogout firebase={firebase} />
                            </Route>

                            <Route exact path="/tutorial">
                                <TutorialPage />
                            </Route>

                            <Route exact path="/privacy">
                                <PrivacyPolicyPage />
                            </Route>

                            <Route 
                                path="/upgrade" 
                                render={({ match: { url } }) => (
                                <>
                                    <UpgradeRequestPage stripePromise={stripePromise} url={url} />
                                </>
                            )} />

                            <Route
                                path="/subscription"
                                render={({ match: { url } }) => (
                                    <>
                                        <SubscriptionRouter url={url} />
                                    </>
                                )}
                            />

                            <Route
                                path="/login"
                                render={({ match: { url } }) => (
                                    <>
                                        <FirebaseLoginPage firebase={firebase} url={url} />
                                    </>
                                )}
                            />

                            <Route
                                path="/portal"
                                render={({ match: { url } }) => (
                                    <>
                                        <Portal url={url} />
                                    </>
                                )}
                            />
                            <Redirect from='*' to='/' />
                        </Switch>
                    </Router>
                </FirebaseAuthProvider>
            </Elements>
        </div>
    );
}

export default App;
