import { Modal, Box } from "@material-ui/core";
import "./videoScreenshotDemoModal.css";

import VideoScreenshotDemoStepper from "../videoScreenshotDemoStepper/videoScreenshotDemoStepper";

const VideoScreenshotDemoModal = ({ modalOpen, closeModal }) => {
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    padding: "20px",
    maxHeight: "100vh",
    overflowY: "auto",
  };
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <VideoScreenshotDemoStepper />
      </Box>
    </Modal>
  );
};

export default VideoScreenshotDemoModal;
