import './plansPage.css';
import UpgradeOption from '../../components/upgradeOption/upgradeOption';
import { useState } from 'react';
import VideoScreenshotDemoModal from '../../components/videoScreenshotDemoModal/videoScreenshotDemoModal';
import chromeIcon from "../../assets/images/chromeIcon.png";


const PlansPage = ({plan}) => {
    let addOrChangeButtonText = "Add Plan"
    let planCost = 0;
    let alreadyHasPlan = false;
    if (plan.priceId !== undefined) {
        planCost = plan.cost;
        addOrChangeButtonText = "Switch to this plan";
        alreadyHasPlan = true;
    }

    let [videoScreenshotDemoModalOpen, setVideoScreenshotDemoModalOpen] = useState(false);

    return (
        <div className="plansPageWrapper">
            <div className="upgradePageHeaderWrapper">
                <h1 className="upgradePageHeader">Choose a plan that works for you</h1>
                <h3 className="upgradePageSmallHeader">Billed monthly, cancel at any time</h3>
            </div>
            
            <div className="availablePlansWrapper">
                {/* <h/portal/account1 className="availablePlansHeader">Available Plans</h1> */}
                <div className="planOptionsWrapper">
                    <UpgradeOption mainHeader={"Basic"}  numberOfCredits={400}  cost={4} includePerMonth={true} benefits={["400 credits per month"]} buttonText={addOrChangeButtonText} buttonEnabled={true} currency="USD" buttonLinkAddress={`upgrade?credits=400`} isActive={planCost === 4} alreadyHasPlan={alreadyHasPlan} />
                    <UpgradeOption mainHeader={"Pro"} benefits={["Unlimited Video Screenshot"]} includeCreditsBenefit={true} smallHeader={"BEST VALUE"} cost={6}  includePerMonth={true} buttonText={addOrChangeButtonText} buttonEnabled={true} currency="USD" buttonLinkAddress="dynamic" id="changeable" numberOfCredits={1000} isActive={planCost !== 0 && planCost % 6 === 0} activeCounterValue={(planCost !== 0 && planCost % 6 === 0) ? planCost / 6 : 1} alreadyHasPlan={alreadyHasPlan} onVideoScreenshotDemoClicked={() => setVideoScreenshotDemoModalOpen(true)} />
                </div>
                <VideoScreenshotDemoModal modalOpen={videoScreenshotDemoModalOpen} closeModal={() => setVideoScreenshotDemoModalOpen(false)}/>
            </div>
            <div className="bottomWrapper">
            <p className="asterixText">* Pressing the Selectext toggle in the corner of the video to detect text uses 1 credit. Your used credits reset to 0 every month.</p>
            <p className="asterixText" style={{marginTop: 8, display: 'flex', alignItems: "center"}}><img alt="Chrome Icon" className="chromeIconUpgrade bottomChromeIcon" src={chromeIcon}></img>The video screenshot feature is only supported on the Chrome browser</p>
            </div>
        </div>
    );
}

export default PlansPage;