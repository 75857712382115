import './upgradeSuccess.css';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const UpgradeSuccess = ({newCreditsAmount, isUpgrading, oldPlanPeriodEndDateString}) => {
    return ( 
        <div className='upgradeFailureWrapper'>
            <div className="prorationPreviewModalWrapper">
            <h2>Your subscription was successfully {isUpgrading ? 'upgraded!': 'set to downgrade'}</h2>
            { isUpgrading ?
            <p>Your card was charged successfully, and your credits have been set to 0/{newCreditsAmount}</p>
            : 
            <p>Your subscription was set to downgrade at the end of your billing period ({oldPlanPeriodEndDateString})</p>
            }
            <Link to="/portal" className="plainLink">
                <Button className="donateButton returnToPortalButton">Return to portal</Button>
            </Link>
            </div>
        </div>
    );
}
 
export default UpgradeSuccess;