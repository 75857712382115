import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './upgradeFailure.css';

const UpgradeFailure = ({errorMessage, isUpgrading}) => {
    return ( 
        <div className='upgradeFailureWrapper'>
            <div className="prorationPreviewModalWrapper">
            <h2>There was an error {isUpgrading ? "upgrading": "downgrading"} your subscription</h2>
            <p className="upgradeFailureErrorMessage">{errorMessage}</p>
            <p>Please send an email to support@selectext.app if you have any questions</p>
            <Link to="/portal" className="plainLink">
                <Button className="donateButton returnToPortalButton">Return to portal</Button>
            </Link>
            </div>
        </div>
    );
}
 
export default UpgradeFailure;