import { Switch, Route, Redirect } from "react-router-dom";
import SubscriptionSuccessPage from "../../pages/subscriptionSuccessPage/subscriptionSuccessPage";

const SubscriptionRouter = ({ url }) => {
    return ( 
        <Switch>
            <Route path={`${url}/success`} component={SubscriptionSuccessPage} />
            <Redirect from={`${url}*`} to={'/portal'} />
        </ Switch> 
    );
}

export default SubscriptionRouter;