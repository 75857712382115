import { useEffect, useState } from "react";
import { requestApi } from "../../utils/api";
import Loading from "../../components/loading/loading";
import { useHistory, useLocation } from "react-router-dom";
import * as qs from 'qs';
import ProrationPreview from "../../components/prorationPreview/prorationPreview";
const UpgradeRequestPage = ({ stripePromise, url }) => {
    const location = useLocation();
    const [upcomingInvoiceData, setUpcomingInvoiceData] = useState(null);
    
    const history = useHistory()
    useEffect(
        () => {
            const credits = parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).credits, 10);
            const switchingPlan = qs.parse(location.search, { ignoreQueryPrefix: true }).switch;
            requestApi(switchingPlan === "true" ? "switch-preview": "subscribe", "POST", { credits: credits }, undefined, true).catch(console.error).then(
                (res) => {
                    if (res !== undefined) {
                        res.json().then(
                            (json) => {
                                if (switchingPlan !== "true") {
                                    const sessionId = json.session_id
                                    stripePromise.then(
                                        (stripe) => {
                                            stripe.redirectToCheckout({
                                                sessionId: sessionId,
                                            }).then(
                                                (res) => {
                                                    console.log(res)
                                                },
                                                (err) => {
                                                    console.log(err)
                                                }
                                            )
                                        }
                                    )
                                } else {
                                    setUpcomingInvoiceData(json)
                                }

                            }
                        )
                    } else {
                        history.push("/portal")
                    }
                }
            )
        }, [stripePromise, history, location]
    )
    return (
    <>
    {upcomingInvoiceData === null ?
    <div className={"fullscreenCenter"}>
        <div className={"loadingWrapper"}>
            <Loading />
        </div>
    </div> : <ProrationPreview upcomingInvoiceData={upcomingInvoiceData} />}
    </>);
}

export default UpgradeRequestPage;