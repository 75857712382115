import './loginSuccessPage.css';  
import logo from '../../assets/images/selectext-logo-filled.svg'


const LoginSuccessPage = () => {
  return ( 
    <div className={"loginSuccessPageWrapper"}>
      <img src={logo} className={"loginSuccessLogo"} alt={"Selectext logo"}></img>
      <h1>You have successfully logged in to Selectext with Google.</h1>
      <h3>You can now close this tab and return to the video.</h3>
    </div>
  );
}
 
export default LoginSuccessPage;