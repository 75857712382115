import "./upgradeOption.css";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState } from "react";
import plusIcon from "../../assets/images/plus.svg";
import minusIcon from "../../assets/images/minus.svg";
import chromeIcon from "../../assets/images/chromeIcon.png";

const maxCounterValue = 10;

const UpgradeOption = ({
  smallHeader,
  mainHeader,
  cost,
  includePerMonth,
  benefits,
  buttonText,
  buttonEnabled,
  currency,
  buttonLinkAddress,
  id,
  numberOfCredits,
  extraStyles,
  resetsInText,
  buttonFunction,
  isActive,
  activeCounterValue,
  alreadyHasPlan,
  includeCreditsBenefit,
  onVideoScreenshotDemoClicked,
  isAccountPage,
}) => {

  const [counterValue, setCounterValue] = useState(
    activeCounterValue !== undefined ? activeCounterValue : 1
  );

  if (extraStyles === undefined) {
    extraStyles = {};
  }

  const dynamicButtonDisabled =
    (isActive !== undefined &&
      isActive === true &&
      activeCounterValue !== undefined &&
      activeCounterValue === counterValue) ||
    !buttonEnabled;
  const staticButtonDisabled =
    (isActive !== undefined && isActive === true) || !buttonEnabled;

  return (
    <div className="upgradeOptionWrapperWrapper">
      <div className="upgradeOptionWrapper" style={extraStyles}>
        <div className="upgradeOptionHeaderBlock">
          <h4 className="upgradeOptionSmallHeader">{smallHeader}</h4>
          <h2 className="upgradeOptionHeader">{mainHeader}</h2>
        </div>
        <div className="upgradeOptionContentBlock">
          <div className="costAreaWrapper">
            <h3 className="upgradeOptionCostHeader">
              {typeof cost === "string" || cost instanceof String
                ? "Custom"
                : cost === 0
                ? `$${cost}`
                : `$${cost * counterValue}`}
              {currency && (
                <span className="upgradeCurrencyText">{currency}</span>
              )}
            </h3>
            {includePerMonth ? (
              <p className="perMonthText">/month</p>
            ) : (
              <p className="perMonthText" style={{ display: "none" }}>
                /month
              </p>
            )}
          </div>
          <div className="upgradeOptionBenefitsWrapper">
            {includeCreditsBenefit && id === "changeable" && (
              <div className="inlineControlWrapper">
                <div className="incrementControlsWrapper">
                  <button
                    className="decrementButton crementButton"
                    disabled={counterValue === 1}
                    style={{ marginRight: -25 }}
                    onClick={() => {
                      if (counterValue > 1) {
                        setCounterValue(counterValue - 1);
                      }
                    }}
                  >
                    <p className="crementText">
                      <img alt={"Minus sign icon"} src={minusIcon}></img>
                    </p>
                  </button>
                  <p className="incrementalCounter">
                    {counterValue * numberOfCredits}
                  </p>
                  <button
                    className="incrementButton crementButton"
                    disabled={counterValue === maxCounterValue}
                    style={{ marginLeft: -25 }}
                    onClick={() => {
                      if (counterValue < maxCounterValue) {
                        setCounterValue(counterValue + 1);
                      }
                    }}
                  >
                    <p className="crementText">
                      <img alt={"Plus sign icon"} src={plusIcon}></img>
                    </p>
                  </button>
                </div>
                <p
                  className="upgradeOptionBenefitsText"
                  style={{ marginLeft: 5 }}
                >
                  credits per month<sup>*</sup>
                </p>
              </div>
            )}

            {benefits !== undefined &&
              benefits.map((benefit) => {
                if (benefit.toLowerCase().endsWith("video screenshot")) {
                  return (
                    <div className="unlimVidScreenshotWrapper" style={{marginBottom: isAccountPage ? 20 : 0}}>
                      <p className="upgradeOptionBenefitsText">
                        <button
                          onClick={onVideoScreenshotDemoClicked ? onVideoScreenshotDemoClicked: () => {}}
                          className="unlimVideoScreenshotButton"
                        >
                          Unlimited Video Screenshot
                          <img  alt="Chrome Icon" className="chromeIconUpgrade" src={chromeIcon}></img>
                        </button>
                      </p>
                    </div>
                  );
                }
                return (
                  <p className="upgradeOptionBenefitsText">
                    {benefit}
                    {benefit.endsWith("credits per month") && <sup>*</sup>}
                  </p>
                );
              })}

            {resetsInText !== undefined && (
              <p className="resetsInText">{resetsInText}</p>
            )}
          </div>
          {mainHeader === "Business" ? (
            <div className="flipCardWrapper">
              
            </div>
          ) : buttonFunction === undefined ? (
            buttonLinkAddress === "dynamic" ? (
              <>
                {dynamicButtonDisabled ? (
                  <Button
                    className="donateButton upgradeButton"
                    disabled={dynamicButtonDisabled}
                  >
                    {dynamicButtonDisabled ? "Current Plan" : buttonText}
                  </Button>
                ) : (
                  <Link
                    className="upgradeButtonLink"
                    to={`/upgrade?credits=${
                      numberOfCredits * counterValue
                    }&switch=${alreadyHasPlan ? "true" : "false"}`}
                  >
                    <Button
                      className="donateButton upgradeButton"
                      disabled={dynamicButtonDisabled}
                    >
                      {dynamicButtonDisabled ? "Current Plan" : buttonText}
                    </Button>
                  </Link>
                )}
              </>
            ) : (
              <>
                {staticButtonDisabled ? (
                  <Button
                    className="donateButton upgradeButton"
                    disabled={staticButtonDisabled}
                  >
                    {staticButtonDisabled ? "Current Plan" : buttonText}
                  </Button>
                ) : (
                  <Link
                    className="upgradeButtonLink"
                    to={`/${buttonLinkAddress}${
                      !buttonLinkAddress.endsWith("plans")
                        ? alreadyHasPlan
                          ? "&switch=true"
                          : "&switch=false"
                        : ""
                    }`}
                  >
                    <Button
                      className="donateButton upgradeButton"
                      disabled={staticButtonDisabled}
                    >
                      {staticButtonDisabled ? "Current Plan" : buttonText}
                    </Button>
                  </Link>
                )}
              </>
            )
          ) : (
            <Button
              className="donateButton upgradeButton"
              disabled={
                (isActive !== undefined && isActive) === true || !buttonEnabled
              }
              onClick={buttonFunction}
            >
              {(isActive !== undefined && isActive === true) || !buttonEnabled
                ? "Current Plan"
                : buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradeOption;
