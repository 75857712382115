import './prorationPreview.css';
import { Button } from '@material-ui/core';
import logo from '../../assets/images/selectext-logo-filled.svg'
import { requestApi } from '../../utils/api';
import { useState } from 'react';
import Loading from '../loading/loading';
import UpgradeFailure from '../upgradeFailure/upgradeFailure';
import UpgradeSuccess from '../upgradeSuccess/upgradeSuccess';
import { useHistory } from 'react-router-dom';


const ProrationPreview = ({ upcomingInvoiceData }) => {
    const oldPlanInfo = upcomingInvoiceData.oldPlanInfo;
    const newPlanInfo = upcomingInvoiceData.newPlanInfo;
    const nextPlanInfo = upcomingInvoiceData.nextPlanInfo;
    const isUpgrading = oldPlanInfo.cost < newPlanInfo.cost;
    const isSwitchingToSameSubscription = oldPlanInfo.cost === newPlanInfo.cost;
    const history = useHistory();
    if (isSwitchingToSameSubscription) {
        history.push("/portal")
    }
    const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const oldPlanPeriodEndDate = new Date(oldPlanInfo.periodEnd * 1000);
    const oldPlanPeriodEndDateString = oldPlanPeriodEndDate.toLocaleDateString(undefined, dateFormatOptions)

    const [attemptUpgradeLoading, setAttemptUpgradeLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [upgradeSuccess, setUpgradeSuccess] = useState(false);

    async function upgradeOrDowngradeSubscription() {
        if (isUpgrading === true) {
            setAttemptUpgradeLoading(true)
            try {
                await requestApi("upgrade", "POST", { credits: newPlanInfo.limit }, undefined, true)
                setUpgradeSuccess(true)
            } catch (e) {
                setErrorMessage(e.message)
            }
            setAttemptUpgradeLoading(false)
        } else {
            setAttemptUpgradeLoading(true);
            try {
                await requestApi("downgrade", "POST", { credits: newPlanInfo.limit }, undefined, true)
                setUpgradeSuccess(true)
            } catch (e) {
                setErrorMessage(e.message)
            }
            setAttemptUpgradeLoading(false)
        }
    }
    return (
        <>{
            attemptUpgradeLoading ?
                <div className={"fullscreenCenter"}>
                    <div className={"loadingWrapper"}>
                        <Loading />
                    </div>
                </div>
                :
                errorMessage === undefined ?
                upgradeSuccess === false ?
                <div className="prorationPreviewWrapper">
                    <div className="prorationPreviewModalWrapper">
                        <img src={logo} alt="Selectext Logo" className="selectextLogoSwitchPlan"></ img>
                        <h2>You have requested to switch from the '{oldPlanInfo.name}' plan to the '{newPlanInfo.name}' plan</h2>
                        {
                            isUpgrading === true ?
                                <>
                                    <p className="upgradingConfirmationMessage">You will immediately be switched to the '{newPlanInfo.name}' plan.<br />
                                        Your billing cycle will be changed to start now, and your credits will reset to 0/{newPlanInfo.limit}.<br />
                                        Your card saved in Stripe will immediately be charged for the first month of the '{newPlanInfo.name}' plan.
                                    </p>
                                </>
                                : <p className="upgradingConfirmationMessage">
                                    Your '{oldPlanInfo.name}' plan will remain active until the end of your monthly billing cycle ({oldPlanPeriodEndDateString}).<br />
                                    At the end of your monthly billing cycle your plan will be switched to the '{newPlanInfo.name}' plan, and your card will be charged {newPlanInfo.cost}USD instead of {oldPlanInfo.cost}USD.
                                </p>
                        }
                        { nextPlanInfo !== undefined && <p className="downgradeCancelledWarningMessage">Warning: your existing request to downgrade your subscription to '{nextPlanInfo.name}' plan on {oldPlanPeriodEndDateString} will be cancelled</p>}
                        { oldPlanInfo.isCancelled !== undefined && oldPlanInfo.isCancelled === true && <p className="downgradeCancelledWarningMessage">Warning: your existing request to cancel your '{oldPlanInfo.name}' plan on {oldPlanPeriodEndDateString} will be cancelled.</p>}
                        <Button className={"donateButton confirmSwitchPlanButton"} onClick={upgradeOrDowngradeSubscription}>Confirm Switch Plan{isUpgrading === true && ` and Pay ${newPlanInfo.cost}USD`}</Button>
                    </div>
                </div>
                :
                <UpgradeSuccess newCreditsAmount={newPlanInfo.limit} isUpgrading={isUpgrading} oldPlanPeriodEndDateString={oldPlanPeriodEndDateString} />
                :
                <UpgradeFailure errorMessage={errorMessage} isUpgrading={isUpgrading} />
        }</>
    );
}

export default ProrationPreview;