

import './videoDemo.css'
import ReactPlayer
    from 'react-player'

const VideoDemo = ({ url }) => {
    return (
        <div className={"videoDemoWrapper"}>
            <div className={"playerSize"}>
                <div className={"aspectRatioWrapper"}>
                    <ReactPlayer width={"100%"} height={"100%"} className={"youtubePlayer"} url={url} muted playing={true} controls={true} />
                </div>
            </div>
        </div>
    )
}

export default VideoDemo