import Header
    from "../../components/header/header";
import VideoDemo
    from "../../components/videoDemo/videoDemo";
import React
    from "react";
import './homePage.css';
import TopNavBar from "../../components/topNavBar/topNavBar";
import AnimationBlock from "../../components/animationBlock/animationBlock";
import config from "../../config";

const HomePage = ({ stripePromise, firebase }) => {
    return (
        <div className={"homePageWrapper"}>
            <TopNavBar />
            <div className={"lrPad"}>
                <Header />
            </div>
            
            <AnimationBlock />
            <div className={"lrPad"} style={{backgroundColor: "white"}} >
                <VideoDemo url={config.promoVideoUrl}/>
                
            </div>
            {/* <LandingContentBlock /> */}
            
        </div>
    )
}

export default HomePage;