import './tutorialPage.css';

import logo from '../../assets/images/selectext-logo-filled.svg'
import VideoDemo from '../../components/videoDemo/videoDemo';
import { TextareaAutosize } from '@material-ui/core';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';
import TopNavBar from '../../components/topNavBar/topNavBar';
import TopBanner from '../../components/topBanner/topBanner';


const TutorialPage = () => {
    const location = useLocation();
    const showNav = qs.parse(location.search, { ignoreQueryPrefix: true }).n !== undefined;
    return (
        <>
        {showNav && <><TopNavBar /><TopBanner /></>}

        <div className="tutorialPageWrapper">
            <img className={"logoHeaderSmall"} alt={"Selectext logo"} style={{display: showNav ? 'none' : 'block' }} src={logo} />
            <h1 className="tutmainheader">How to use Selectext</h1>
            <p className='followStepsBelow'>The video below explains how to use Selectext.<br />You can even try using Selectext directly on this video and paste it in the text area below.<span className='youWillBePromptedText'><br />You will be prompted to sign in with Google the first time you use Selectext.</span></p>
            <VideoDemo url="https://vimeo.com/950231716" />
            <TextareaAutosize
                minRows={10}
                placeholder="Paste the text here"
                className='tuttextarea'
            />

            <div className='byebyewrapper'>
                <h1 className='byebyetext'>Now go and try Selectext on some other videos!</h1>
                <p className='byebyesmalltext'>If you love it, we would really appreciate a review.</ p>
                <p className='byebyesmalltext'>If you can't get it working, email support@selectext.app and we will try our best to help you out.</ p>
            </div>
        </div >
        </>
    )
}

export default TutorialPage