import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TutorialGif from "../../components/tutorialgif/tutorialgif";
import "./subscriptionSuccessPage.css";
import managesubscriptiongif from "./gifs/managesubscription.gif";
import { useLocation } from "react-router-dom";
import VideoScreenshotDemoStepper from "../../components/videoScreenshotDemoStepper/videoScreenshotDemoStepper";
import { useEffect } from "react";


const SubscriptionSuccessPage = () => {
  const location = useLocation();
  const isPro = location.search.includes("Pro");
  useEffect(() => {
    if (isPro) {
      window.postMessage({ type: "SELECTEXT_VIDEO_SCREENSHOT_UNLOCK" }, "*");
    }
  }, [isPro])
  return (
    <div className="subscriptionSuccessPageWrapper">
      <h1 className="thanksForSubscribing">
        Thank you for subscribing to the Selectext {isPro ? "Pro" : "Basic"}{" "}
        plan
      </h1>
      <Link to="/portal" className="plainLink">
        <Button
          className="donateButton returnToPortalButton"
          style={{ marginTop: 0 }}
        >
          Return to portal
        </Button>
      </Link>
      {isPro && (
        <div className="screenshotUnlockedWrapper">
          <h3 className="youHaveUnlockedVideoScreenshotText">You have unlocked the video screenshot feature</h3>
          <div className="stepperSuccessWrapper">
          <VideoScreenshotDemoStepper />
          </div>
        </div>
      )}
      <TutorialGif
        header={"How to manage your subscription"}
        name={"manage subscription"}
        desc={
          "Click the manage subscription button on the 'account' tab to open the Stripe customer portal. If you cancel mid-month, your premium plan will still last until the end of the month."
        }
        gif={managesubscriptiongif}
      ></TutorialGif>
    </div>
  );
};

export default SubscriptionSuccessPage;
