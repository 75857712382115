import * as React from "react";
import {
    FirebaseAuthConsumer,
} from "@react-firebase/auth";
import { requestApi } from "../../utils/api";
import "./firebaseLogin.css"
import Loading from "../loading/loading"
import { useHistory } from "react-router-dom";

export const FirebaseLogin = ({ firebase, url, goto }) => {
    const history = useHistory()
    const getAndSendIDToken = () => {
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            requestApi("login", 'POST', null, {"Authorization": `Bearer ${idToken}`}, true).catch(console.error)
                .then(
                    (res) => {

                        if (res !== undefined) {
                            if (goto === "extension") {
                                window.postMessage({ type: "SELECTEXT_SIGN_IN_SUCCESS" }, "*");
                                history.push(`${url}/success`)
                            } else if (goto === "portal") {
                                history.push(`/portal`)
                            }
                        } else {
                            console.log("There was an error logging in with firebase")
                        }
                    }
                );
        })
    }

    return (
        <div>
            
                <FirebaseAuthConsumer>
                    {({ isSignedIn, user, providerId }) => {
                        if (providerId) {
                            if (isSignedIn === true) {
                                getAndSendIDToken();
                                return (<div className={"fullscreenCenter"}>
                                    <div className={"loadingWrapper"}>
                                        <Loading />
                                    </div>
                                </div>)
                            } else {
                                const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                googleAuthProvider.setCustomParameters({
                                    prompt: 'select_account'
                                    });
                                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                                    .then(() => {
                                        // Existing and future Auth states are now persisted in the current
                                        // session only. Closing the window would clear any existing state even
                                        // if a user forgets to sign out.
                                        // ...
                                        // New sign-in will be persisted with session persistence.
                                        return firebase.auth().signInWithRedirect(googleAuthProvider);
                                    })
                            }
                        } else {
                            return (
                                <div className={"fullscreenCenter"}>
                                    <div className={"loadingWrapper"}>
                                        <Loading />
                                    </div>
                                </div>
                            )
                        }
                    }}
                </FirebaseAuthConsumer>
        </div>
    );
};

export default FirebaseLogin