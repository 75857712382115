import React, { useEffect, useState } from 'react';
import './portal.css';
import {
    Route,
    Redirect,
    Switch,
    useHistory
} from "react-router-dom";
import AccountPage from '../accountPage/accountPage';
import PortalNavbar from '../../components/portalNavbar/portalNavbar';
import { requestApi } from '../../utils/api';
import Loading from "../../components/loading/loading";
import defaultUserIcon from '../../assets/images/default-user-icon.jpeg'
import PlansPage from '../plansPage/plansPage';
import TutorialPage from '../tutorialPage/tutorialPage';


const Portal = ({ url }) => {
    const history = useHistory()
    const [checkComplete, setCheckComplete] = useState(false);

    const [name, setName] = useState("?")
    const [email, setEmail] = useState("?")
    const [plan, setPlan] = useState("?")
    const [usage, setUsage] = useState({"limit": "?", "count": "?"})
    const [picture, setPicture] = useState(defaultUserIcon)
    const [limitReached, setLimitReached] = useState(false);

    useEffect(
        () => {
            if (process.env.REACT_APP_STAGE === "local") {
                setCheckComplete(true)
                setName("Test User")
                setEmail("test@gmail.com")
                setPlan({
                    cost: 12,
                    limit: 2000,
                    name: "Pro - 2000",
                    resetsInSeconds: 1092631,
                    priceId: "blah"
                })
                setUsage({
                    count: 0,
                    limit: 2000
                })
                setPicture("")
                setLimitReached(false)
            } else {
                requestApi("get-user-info", "GET", undefined, undefined, true).catch(console.error).then(
                    (res) => {
                        
                        if (res === undefined) {
                            history.push("/login/portal")
                        } else {
                            res.json().then(
                                (json) => {
                                    setName(json.name)
                                    setEmail(json.email)
                                    setPlan(json.plan)
                                    setUsage(json.usage)
                                    setPicture(json.picture)
                                    setLimitReached(json.usage.count === json.usage.limit)
                                    setCheckComplete(true)
                                }
                            )
                        }
                    }
                )
            }
        }, [history]
    )
    
    return (
        <>
            {checkComplete === true ? 
                <div className="portalWrapper">
                    <PortalNavbar plan={plan} />
                    <div className="portalContentWrapper">
                        <Switch>
                            <Route exact path={`${url}/account`}>
                                <AccountPage name={name} email={email} plan={plan} picture={picture} usage={usage} limitReached={limitReached} />
                            </Route>
                            <Route exact path={`${url}/plans`}>
                                <PlansPage plan={plan} />
                            </Route>
                            <Route exact path={`${url}/tutorial`}>
                                <div className="portalTutorialPageWrapper">
                                    <TutorialPage />
                                </div>
                            </Route>
                            <Redirect from={`${url}*`} to={`${url}/account`} />
                        </ Switch>
                    </div>
                </div>
                : 
                <div className={"fullscreenCenter"}>
                    <div className={"loadingWrapper"}>
                        <Loading />
                    </div>
                </div>
            }
        </>
        
    )
}

export default Portal