import DataField from '../../components/dataField/dataField';
import './accountPage.css'
import { Button, Modal, Box, Typography } from '@material-ui/core';
import { useState } from 'react';
import { requestApi } from "../../utils/api";
import { useHistory } from 'react-router-dom';
import UpgradeOption from '../../components/upgradeOption/upgradeOption';
import VideoScreenshotDemoModal from '../../components/videoScreenshotDemoModal/videoScreenshotDemoModal';



const AccountPage = ({ picture, email, plan, usage, name, limitReached }) => {
    const [ videoScreenshotDemoModalOpen, setVideoScreenshotDemoModalOpen ] = useState(false);
    const onManageSubscription = () => {
        requestApi("customer-portal", "GET", undefined, undefined, true).catch(console.error).then(
            (res) => {
                if (res === undefined) {
                    console.log("There was an error accessing the customer portal");
                } else {
                    res.json().then(
                        (json) => {
                            window.location.href = json.portalUrl;
                        }
                    )
                }
            }
        )
    }
    const generateResetsInText = (resetsInSeconds, isCancelled, isPaid, nextPlanInfo) => {
        var d = Math.floor(resetsInSeconds / (3600*24));
        var h = Math.floor(resetsInSeconds % (3600*24) / 3600);
        let firstWord = "Resets ";
        if (isCancelled) {
            firstWord = "Cancels "
        } else if (isPaid) {
            firstWord = "Renews "
        }

        if (nextPlanInfo !== undefined) {
            firstWord = `Dowgrades to '${nextPlanInfo.name}' plan`
        }
        return `${firstWord} in ${d} day${d !== 1 ? "s" : ""}, ${h} hour${h !== 1 ? "s" : ""}`
    }

    const videoScreenshotUnlocked = plan.hasVideoScreenshot === true;
    let planInfo;
    let resetsInText;
    let isCancelled = false;
    let planStatus = undefined;
    let buttonFunction;
    let planButtonText;
    let showPerMonth = true;
    if (plan === "?") {
        planInfo = {
            credits: "?",
            name: "?",
            cost: "?",
        }
        resetsInText = "?"
    } else {
        buttonFunction = plan.priceId !== undefined ? onManageSubscription : undefined
        planButtonText = plan.priceId !== undefined ? "Manage Subscription" : "Upgrade";
        isCancelled = plan.isCancelled;
        planStatus = plan.status;
        planInfo = plan;
        if (planInfo.priceId === undefined) {
            showPerMonth=false;
        }
        resetsInText = generateResetsInText(plan.resetsInSeconds, isCancelled, plan.priceId !== undefined, plan.nextPlanInfo);
    }
    const [modalOpen, setModalOpen] = useState(false);

    
    const openModal = () => {
        setModalOpen(true);
    }
    const closeModal = () => {
        setModalOpen(false);
    }

    const history = useHistory();

    const onDeleteAccount = () => {
        history.push("/logout")
    }

    


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        padding: "20px",
    };

    return (
        <div className="accountPageWrapper">
            <div className="accountPageLayout">
                <div className="yourMonthlyCreditsLayout">
                    <div className="yourMonthlyCreditsWrapper">
                        <h1 className="yourCreditsHeader" style={{ marginBottom: 0 }}>Your Monthly Credits</h1>
                        <div className="creditsAmountsWrapper">
                            <h3 className="creditsUsed">{usage.count}<span className="creditsUsedSmallText"> used</span></h3>
                            <div className="horizontalLine"></div>
                            <h3 className="creditsAvailable">{usage.limit}<span className="creditsUsedSmallText"> total available</span></h3>
                        </div>
                    </div>
                    <div className="yourPlansWrapper">
                        <h1 className="yourPlanHeader">Your Plan   </h1>
                        <div className="yourPlansCardsrapper">
                            {isCancelled === true && <p className='youHaveCancelledYourPlanText'>You have cancelled your plan. Your plan still lasts until the end of the month.</p>}
                            {planStatus === "past_due" && <p className='youHaveCancelledYourPlanText'>Your plan is in past_due state. Please resolve via 'Manage Subscription' before your plan is cancelled </p>}
                            <UpgradeOption mainHeader={planInfo.name} numberOfCredits={planInfo.credits} cost={planInfo.cost} includePerMonth={showPerMonth} resetsInText={resetsInText} buttonText={planButtonText} buttonEnabled={true} currency={showPerMonth ? "USD" : undefined} buttonFunction={buttonFunction} buttonLinkAddress={"portal/plans"} benefits={videoScreenshotUnlocked ? ["Unlimited Video Screenshot"]: undefined} onVideoScreenshotDemoClicked={videoScreenshotUnlocked ? () => setVideoScreenshotDemoModalOpen(true): undefined} isAccountPage={true} />
                            <VideoScreenshotDemoModal modalOpen={videoScreenshotDemoModalOpen} closeModal={() => setVideoScreenshotDemoModalOpen(false)}/>
                        </div>
                    </div>
                </div>
                <div className="accountPageRightColumnLayout">
                    <div className="accountSettingsLayout">
                        <h1 className="accountSettingsHeader">Account Info</h1>
                        <img alt="user icon" src={picture} className="userIconPicture"></img>
                        <DataField label={"Name"} value={name} />
                        <DataField label={"Email"} value={email} />
                        <DataField label={"Sign in Provider"} value={"Google"} />
                        {planInfo.name === "Free" && <Button className="donateButton deleteAccountButton greyedButton" onClick={openModal}>Delete Account</ Button>}
                    </div>
                    <div className="supportLayout">
                        <h1 className="supportHeader">Support</h1> 
                        <p>Need help? Email support@selectext.app</p>
                    </div>
                </div>
            </div>
            <Modal
                open={modalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        We're sorry to see you go.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete your account?
                    </Typography>
                    <div className="yesNoButtonsWrapper">
                        <Button className="donateButton greyedButton" onClick={onDeleteAccount}>Yes</Button>
                        <Button className="donateButton" onClick={closeModal}>No</Button>
                    </div>
                </Box>
            </Modal>

        </div>
    )
}

export default AccountPage;