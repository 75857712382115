import './portalNavbar.css'
// Import selectext logo
import logo from '../../assets/images/selectext-logo-filled.svg'
import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";


const PortalNavbar = ({plan}) => {
    const [activeOption, setActiveOption] = useState("account");
    const url = window.location.href;
    useEffect(
        () => {
            var parts = window.location.href.split('/');
            var lastSegment = parts.pop() || parts.pop();
            setActiveOption(lastSegment)
        }, [url]
    )

    return (
        <div className="portalNavbar">
            <img src={logo} alt="Selectext Logo" className="selectextLogoNav"></ img>
            <div className="navButtonsWrapper">
                <Link to="account">
                    <div onClick={() => setActiveOption("account")} className={"navbarButton" + (activeOption === "account" ? ' activeNavItem' : '')}>Account</div>
                </Link>
                <Link to="plans">
                    <div onClick={() => setActiveOption("plans")} className={"navbarButton" + (activeOption === "plans" ? ' activeNavItem' : '')}>Plans</div>
                </Link>
                <Link to="tutorial">
                    <div onClick={() => setActiveOption("tutorial")} className={"navbarButton" + (activeOption === "tutorial" ? ' activeNavItem' : '')}>Tutorial</div>
                </Link>
            </div>
            <div className="navLogoutButtonWrapper">
                <Link to="/logout">
                    <div className="navLogoutButton">
                        Log Out
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default PortalNavbar;