import "./videoScreenshotDemoSlide.css";

const VideoScreenshotDemoSlide = ({slide}) => {
  return ( <div className="imgLoadingPlaceholder">
    <div style={{marginTop: "56.25%", width: "100%"}}> </div>
    <img className="videoScreenshotDemoSlide" src={slide.src} alt={slide.name} key={slide.name} >
      </img>

      </div> );
}
 
export default VideoScreenshotDemoSlide;