import './dataField.css'

const DataField = ({ label, value, nomargin, redText }) => {
    return (
        <div className="dataFieldWrapper" style={{marginBottom: nomargin === true ? 0 : 10}}>
            <div className="dataFieldLabel">{label}</div>
            <h4 style={{marginBottom: 0, color: redText ? 'red' : 'black'}}>{value}</h4>
        </div>
    );
}

export default DataField;